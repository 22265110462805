@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cedarville+Cursive&family=Share+Tech&family=Share+Tech+Mono&family=Roboto+Condensed:wght@300;400;700&family=Shadows+Into+Light&family=Space+Grotesk:wght@400;500;600;700&family=Judge:wght@300;400;700&display=swap");

@font-face {
    font-family: "Share Tech";
    font-weight: 400;
    src: url(../assets/fonts/Share_Tech/ShareTech-Regular.ttf) format("ttf");
}

@font-face {
    font-family: "Share Tech Mono";
    font-weight: 400;
    src: url(../assets/fonts/Share_Tech_Mono/ShareTechMono-Regular.ttf)
        format("ttf");
}

@font-face {
    font-family: "Space Grotesk";
    font-weight: 400;
    src: url(../assets/fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf)
        format("ttf");
}

@font-face {
    font-family: "Bebas Neue";
    font-weight: 400;
    src: url(../assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf) format("ttf");
}

@font-face {
    font-family: "Cedarville Cursive";
    font-weight: 400;
    src: url(../assets/fonts/Cedarville_Cursive/CedarvilleCursive-Regular.ttf)
        format("ttf");
}

@font-face {
    font-family: "Roboto Condensed";
    font-weight: 400;
    src: url(../assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf)
        format("ttf");
}

@font-face {
    font-family: "Shadows Into Light";
    font-weight: 400;
    src: url(../assets/fonts/Shadows_Into_Light/ShadowsIntoLight-Regular.ttf)
        format("ttf");
}

@font-face {
    font-family: "Judge";
    font-weight: 700;
    src: url(../assets/fonts/F37_JUDGE_Condensed/F37Judge-BoldCompressed.ttf)
        format("ttf");
}
